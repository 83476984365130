@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: inherit;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  line-height: 1.4rem;
}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, #fafbfc 0%, #dee5eb 30%, #537791 100%);

  /* background: linear-gradient(180deg, #c6d2dd 0%, #4a6f94 100%); */
  position: relative;
}

.primary-text-color {
  color: #3b638c;
}

.question-text {
  font-weight: 700;
  color: #000;
}

.custom-primary-button {
  background: #f9df81;
  border-radius: 8px;
  width: 100%;
  color: #202020;
  font-size: 1rem;
  border: none;
  font-weight: 700;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.secondary-button {
  background: #1b2e48;
  border-radius: 50px;
  width: 100%;
  color: #fff;
  border: none;
  font-weight: 700;
}

.register-button {
  background: #1b2e48;
  border-radius: 30px;
  width: 50%;
  color: #fff;
  border: none;
  font-weight: 700;
  padding: 13px 0px 13px 0px;
}

.large-radio .ant-radio {
  transform: scale(1.4); /* Increase the scale value as needed */
  margin-left: 1.5rem;
}

.radio-container {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.large-radio .ant-radio-wrapper {
  display: flex;
  align-items: center;
}

:where(.css-dev-only-do-not-override-j9bb5n).ant-form-item
  .ant-form-item-explain-error {
  margin-left: 1rem;
}

.radio-container .ant-radio-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.radio-container .ant-radio-input {
  width: 20px;
  height: 20px;
}

.correct-option .ant-radio {
  color: green;
}

.correct-option .ant-radio .ant-radio-inner {
  border-color: green;
}

.correct-option .ant-radio-checked .ant-radio-inner {
  background-color: green;
  border-color: green;
}

.wrong-option .ant-radio {
  color: red;
}

.wrong-option .ant-radio .ant-radio-inner {
  border-color: red;
}

.wrong-option .ant-radio-checked .ant-radio-inner {
  background-color: red;
  border-color: red;
}

.default-option .ant-radio {
  color: blue;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-scrollbar {
  position: relative;
  width: 5rem;
  height: 0.2rem;
  border-radius: 50px;
  margin-top: 10px;
  color: rgba(217, 217, 217, 1);
  background-color: rgba(217, 217, 217, 1);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.f600 {
  font-weight: 600;
}

.f700 {
  font-weight: 700;
}
.f800 {
  font-weight: 800;
}
.f900 {
  font-weight: 900;
}

.fsize1 {
  font-size: 1rem;
}

.fsize2 {
  font-size: 2rem;
}

.fsize3 {
  font-size: 3rem;
}

.bg-yellow {
  background-color: #f0d474;
  border-radius: 2rem;
  width: 40%;
  text-align: center;
  margin: 0.5rem 0rem;
}

.bg-yellow-full-width {
  background-color: #f0d474;
  border-radius: 1rem;
  width: 100%;
  text-align: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.custom-card {
  background-color: #fff;
  border-radius: 1rem;
  width: 100%;
  text-align: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.complication-img {
  flex: 20%;
}

.complication-img-7 {
  width: 40%;
}

.whatisdlp-img {
  width: 28%;
}

.dietary-img {
  width: 22%;
}

.dietary-img-special {
  width: 18.5%;
}

@media (max-width: 768px) {
  .register-button {
    width: 100%;
  }

  .dietary-img {
    width: 35%;
  }

  .dietary-img-special {
    width: 31%;
  }

  .whatisdlp-img {
    width: 33%;
  }

  .complication-img {
    width: 25%;
  }

  .complication-img-7 {
    width: 40%;
  }

  .bg-yellow {
    width: 60%;
    margin: 0rem;
  }
}
